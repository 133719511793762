<template>
  <div class="left-menu">
    <div class="logo">
      <span v-show="showLogo">果冻商城</span>
    </div>
    <el-menu
      ref="menu"
      :default-active="defaultSelectMenu"
      class="el-menu-vertical-demo"
      background-color="#001529"
      text-color="#fff"
      active-text-color="#409EFF"
      unique-opened
      :collapse="!showLogo"
      router
      @open="openMenu"
      @select="selectMenu"
    >
      <template v-for="item in menuList">
        <!-- 不包含子菜单的“一级菜单” -->
        <el-menu-item :index="item.path" :key="item.path" v-if="!item.children">
          <i :class="item.meta.icon"></i>
          <span slot="title">{{ item.meta && item.meta.name }}</span>
        </el-menu-item>

        <!-- 包含子菜单的“一级菜单” -->
        <el-submenu :index="item.path" :key="item.path" v-else>
          <template slot="title">
            <i :class="item.meta.icon"></i>
            <span>{{ item.meta && item.meta.name }}</span>
          </template>
          <!-- 循环渲染“二级菜单” -->
          <template v-for="subItem in item.children">
            <el-menu-item :index="subItem.path" :key="subItem.path" v-if="!subItem.meta.hidden">
              <i :class="subItem.meta.icon"></i>
              {{ subItem.meta && subItem.meta.name }}
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LeftMenu',

  data() {
    return {
      isCollapse: false,
      showLogo: true,
      defaultSelectMenu: this.$route.path,
      currentOpenKey: ''
    }
  },
  computed: {
    ...mapGetters(['getResultRoutes']),

    menuList() {
      const routes = this.getResultRoutes
      return routes.filter(item => {
        return !item.meta.hidden
      })
    }

    // defaultSelect() {
    //   let path = this.$route.fullPath.slice(1)
    //   let rootPath = path.split('/')
    //   return [`/${rootPath[0]}`]
    // }
  },
  mounted() {
    // this.defaultSelectMenu = sessionStorage.getItem('keyPath') || '/channel'
    this.$bus.$on('showLogo', () => {
      this.showLogo = !this.showLogo
    })
  },
  watch: {
    defaultSelectMenu: {
      immediate: true,
      handler(newV, oldV) {
        // if (newV == '/') this.defaultSelectMenu = '/home'
        // if (newV != this.$route.path) {
        //   this.defaultSelectMenu = this.$route.path
        // }
      }
    },
    $route: {
      immediate:true,
      handler(newV, oldV) {
        this.defaultSelectMenu = this.$route.path
      }
    }
  },
  methods: {
    openMenu(index, indexPath) {
      // this.currentOpenKey = index
      // this.defaultSelectMenu = this.menuList.find(item => item.path == index).redirect
      // this.$router.push(this.defaultSelectMenu)
      sessionStorage.setItem('keyPath', this.defaultSelectMenu)
    },
    selectMenu(index, indexPath) {
      sessionStorage.setItem('keyPath', index)
      // if (index == '/home') {
      //   this.$refs.menu.close(`/${this.$route.path.split('/')[1]}`)
      //   this.defaultSelectMenu = index
      // }
    }

    // goPage({ path }) {
    //   console.log(path)
    //   this.$router.push({
    //     path
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.left-menu /deep/ {
  height: 100%;
  overflow-y: scroll;
  .logo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 20px 20px 20px;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    text-align: center;

    .logo__img {
      width: 30px;
      margin-right: 10px;
    }
  }
  .el-menu {
    border: none;
    .el-menu-item {
      color: rgba(255, 255, 255, 0.8) !important;
    }
    .el-submenu__title {
      color: rgba(255, 255, 255, 0.8) !important;
      i {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
  }
  .el-menu--collapse {
    width: 100%;
  }

  .el-submenu {
    .el-menu-item {
      padding: 0 0 0 50px !important;
    }
  }
  .el-menu-item.is-active {
    background-color: rgb(64, 158, 255) !important;
    color: #fff !important;
  }
}
.left-menu::-webkit-scrollbar {
  width: 2px;
  background-color: #ccc;
}
</style>
